import React from 'react';
import Head from 'next/head';
import { Container, Row, Col } from 'react-bootstrap';
import Link from 'next/link';
import PublicTopNavigation from 'components/Navigation/PublicTopNavigation';
import PublicFooter from 'components/Footer/PublicFooter';
import { translate as t } from 'utils/helpers';
import styles from 'pages/[podcast-name]/privacy-policy/InfoPages.module.scss';
import useLang from 'context/LanguageContext';
import routes from 'utils/routes';

const ErrorPage = () => {
  const { lang } = useLang();
  return (
    <div className="page nosidebar">
      <Head>
        <title>404 Not Found | Mumbler</title>
        {/*<link rel="icon" href="/favicon.png" />*/}
        <meta name="robots" content="noindex" />
      </Head>

      <main className="main">
        <Container className="full mobile-full" fluid>
          <Row className={styles.mainRow}>
            <Col lg={12} className={styles.primary}>
              <PublicTopNavigation lang={lang} />

              <Container
                className={`${styles.contentWrap} full mobile-full`}
                fluid>
                <Container
                  className={`${styles.contentChild} ${styles.errorContent} ${styles.mainError} wider`}>
                  <span className={`${styles.backgroundText}`}>
                    {t(lang, 'common')('error')}
                  </span>
                  <img src="/error.svg" alt="Error" className="img-fluid" />
                  <h1 className={styles.sectionHeading}>
                    {t(lang, 'common')('error404PageNotFound')}
                  </h1>

                  <p>
                    {t(
                      lang,
                      'common',
                    )(
                      'pleaseTryAgainAfterSomeTimeIfYouLandedHereByMistake',
                    )}{' '}
                    <a href={routes.help} target="_blank">{t(lang, 'common')('letusKnow')}</a>
                    .
                  </p>
                </Container>
              </Container>

              <PublicFooter lang={lang} />
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
};

// ErrorPage.propTypes = {
//   lang: PropTypes.oneOf(supportedLanguages),
// };

export default ErrorPage;
